<template>
    <div id="detail">
        <bg-img :name="name"></bg-img>
        <div class="con-top">
            <div class="con-title" v-html="result.title"></div>
            <div class="con-all">
                时间：<span v-html="result.time"></span>
                来源：<span v-html="result.laiyuan"></span>
            </div>
        </div>
        <div class="con-body" v-html="result.content"></div>
    </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    import axios from 'axios'
    export default{
        components:{
            BgImg
        },
        data(){
            return{
                name:'详情',
                result:[]
            }
        },
        created(){
            const name = this.$route.params.name;
            const id = this.$route.params.id;
            if(name == 'project'){
                //项目
                axios.post('/api/index/projectone',{
                    id: id
                }).then((res)=>{
                    this.result = res.data[0]
                })
            }else if(name == 'news'){
                //新闻
                axios.post('/api/index/newsone',{
                    id: id
                }).then((res)=>{
                    this.result = res.data[0]
                })
            }else if(name == 'work'){
                axios.post('/api/index/workone',{
                    id: id
                }).then((res)=>{
                    this.result = res.data[0]
                })
            }else if(name == 'service'){
                axios.post('/api/index/serviceone',{
                    id: id
                }).then((res)=>{
                    this.result = res.data[0]
                })
            }else if(name == 'customproject'){
                axios.post('/api/index/customprojectone',{
                    id: id
                }).then((res)=>{
                    this.result = res.data[0]
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
    #detail{
        padding-bottom: 50px;
        .con-top{
            width: 1000px;
            margin: 40px auto 0;
            background: #fafafa;
            min-height: 80px;
            .con-title{
                font-size: 18px;
                line-height: 40px;
                text-align: center;
                color: #333;
                padding-top: 10px;
            }
            .con-all{
                text-align: center;
                margin-top: 0;
                line-height: 30px;
                padding-bottom: 10px;
                color: #777;
                font-size: 14px;
                span:first-child{
                    margin-right: 30px;
                }
            }
        }
        .con-body{
            width: 1000px;
            margin: 40px auto;
        }
    }

</style>