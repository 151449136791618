import Vue from 'vue';
import Router from 'vue-router';

import Index from './components/index';
import AboutBox from './components/about';
import About from './components/about/about.vue';
import Content from './components/about/content.vue';
import Ry from './components/about/ry.vue';
import Detail from './components/detail.vue';
import Project from './components/project.vue';
import CustomProject from './components/custom_project.vue';
import News from './components/news.vue';
import New from './components/news/new.vue'
import NewZbgs from './components/news/new_zbgs.vue'
import Down from './components/news/down.vue'
import Need from './components/news/need.vue'
import List from './components/list.vue'
import CustomList from './components/custom_list.vue'
import Cxyzx from './components/cxyzx.vue'
import Cxyabout from './components/cxyzx/about.vue'
import CxyConcate from './components/cxyzx/concate.vue'
import Service from './components/cxyzx/service.vue'
import Work from './components/cxyzx/work.vue'
import axios from 'axios'
import store from './store';
Vue.use(Router)
export default new Router({
    mode: 'history',
    // mode:'hash',
    linkExactActiveClass: 'active',
    routes: [
        { path: '/', name: 'Index', component: Index },
        //关于我们
        {
            path: '/about',
            name: 'AboutBox',
            component: AboutBox,
            linkExactActiveClass: 'active',
            children: [{
                    path: '',
                    redirect: 'aboutMe'
                },
                {
                    path: 'aboutMe',
                    component: About
                },
                {
                    path: 'content',
                    component: Content
                },
                {
                    path: 'ry',
                    component: Ry
                },
                {
                    path: 'news/2',
                    component: Down
                },
                {
                    path: 'news/3',
                    component: Need
                },
            ]
        },
        //产品中心
        {
            path: '/project/',
            name: 'Project',
            component: Project,
            // beforeEnter(to, from, next){
            //
            // },
            // redirect:'/project/'+ newid,
            children: [{
                    path: '',
                    beforeEnter(to, from, next) {
                        if (!to.params.id) {
                            axios.post('/api/index/list').then((res) => {
                                if(res.data.length){
                                    let nsid = res.data[0].id
                                    let title = res.data[0].title
                                    store.commit('showTitle', undefined)
                                    sessionStorage.setItem('title', title);
                                    next({ path: '/project/' + nsid })
                                }else{
                                    next()
                                }
                                return
                            })
                        }

                    },
                    component: List,
                    meta: {
                        keepAlive: false
                    }
                },
                {
                    path: ':id',
                    component: List
                }

            ]
        },
        {
            path: '/cxyzx',
            name: 'Cxyzx',
            component: Cxyzx,
            children: [{
                    path: 'about',
                    component: Cxyabout
                },
                {
                    path: 'concate',
                    component: CxyConcate
                },
                {
                    path: 'service/:id',
                    beforeEnter(to, from, next) {
                        if (!to.params.id || to.params.id == 0) {
                            axios.post('/api/index/servicelist').then((res) => {
                                if(res.data.length){
                                    let nsid = res.data[0].id
                                    let title = res.data[0].title
                                    store.commit('showTitle', undefined)
                                    sessionStorage.setItem('title', title);
                                    next({ path: '/cxyzx/service/' + nsid })
                                }else{
                                    next()
                                }
                                return
                            })
                        }else{
                            next()
                        }
                    },
                    component: Service
                },
                {
                    path: 'work/:id',
                    beforeEnter(to, from, next) {
                        if (!to.params.id || to.params.id == 0) {
                            axios.post('/api/index/worklist').then((res) => {
                                if(res.data.length){
                                    let nsid = res.data[0].id
                                    let title = res.data[0].title
                                    store.commit('showTitle', undefined)
                                    sessionStorage.setItem('title', title);
                                    next({ path: '/cxyzx/work/' + nsid })
                                }else{
                                    next()
                                }
                                return
                            })
                        }else{
                            next()
                        }
                    },
                    component: Work
                }
            ]
        },
        //新闻、资料、招聘
        {
            path: '/news',
            name: 'News',
            component: News,
            children: [{
                    path: '1',
                    component: New
                },
                {
                    path: '4',
                    component: NewZbgs
                }
            ]
        },
        //详情
        {
            path: '/detail/:name/:id',
            component: Detail,
            children: [{
                path: '',
                component: Detail,
            }]
        },
        //客户案例
        {
            path: '/customproject/',
            name: 'CustomProject',
            component: CustomProject,
            children: [{
                    path: '',
                    beforeEnter(to, from, next) {
                        if (!to.params.id) {
                            axios.post('/api/index/customlist').then((res) => {
                                if( res.data.length){
                                    let nsid = res.data[0].id
                                    let title = res.data[0].title
                                    store.commit('showTitle', undefined)
                                    sessionStorage.setItem('title', title);
                                    next({ path: '/customproject/' + nsid })
                                }else{
                                    next()
                                }
                                return
                            })
                        }
                    },
                    component: CustomList,
                    meta: {
                        keepAlive: false
                    }
                },
                {
                    path: ':id',
                    component: CustomList
                }

            ]
        },
    ]
})