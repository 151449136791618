import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import page from 'v-page';
import store from './store'

import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper);
Vue.use(page,{
    pageSizeMenu:2
})
new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  router,
    store
}).$mount('#app')
