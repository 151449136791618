<template>
    <div class="content">
        <!-- 轮播图 -->
            <swiper :options="swiperOptions" style="width:100%;height:400px;" ref="mySwiper">
                <swiper-slide v-for="item of bannerList" :key="item.id" style="display:block;width:100%;height:100%;">
                    <a :href="item.link" target="_blank" style="display:block;width:100%;height:100%;">
                        <div :style="'background:url('+ url+item.picture +') center center no-repeat;width:100%;height:100%;'"></div>
                        <!-- <img :src="url+item.picture" style="width:100%;"/> -->
                    </a>
                </swiper-slide>

                <div class="swiper-pagination"  slot="pagination"></div>

            </swiper>
        <div class="otrSweiper">
            <div class="p_list">
                <div class="one_k" v-for="item in p_nav_list" @click="changeitem(item)">
                    <div class="one_hull">
                        <img :src="active_id==item.id?item.pic2:item.pic1" alt="" />
                    </div>
                    <p>{{item.title2||item.title}}</p>
                </div>
            </div>
            <div class="c_list">
                <swiper :options="swiperOptions2" style="width:100%;height:220px;" ref="mySwiper2">
                    <swiper-slide v-for="item of active_child" :key="item.id" style="display:block;width:100%;height:100%;">
                        <a :href="'/project/'+item.id" target="_blank" style="display:block;width:100%;height:100%;position:relative;">
                            <img :src="url+item.pic" style="width:100%;height:100%;" alt="">
                            <p class="one_p_title">{{item.title2||item.title}}</p>
                        </a>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </div>
            <div class="linkTo" style="margin-top:30px;">
                <router-link :to="'/project/'+active_id" target="_blank">查看更多</router-link>
            </div>
        </div>
        <!-- 客户案例 -->
        <div class="project" style="margin-top:20px;">
            <div class="titlels">
                <span class="span-title">客户案例</span>
            </div>
            <p class="pro-sum">用心做好每一件产品，你们的信任就是我们的坚持</p>
            <ul>
                <li v-for="item of project.rows" :key="item.id" :id="item.id">
                    <router-link :to="{path:'/detail/customproject/'+item.id}" target="_blank">
                        <img :src="url+item.picture" alt="">
                        <p v-html="item.title" class="pro-title"></p>
                    </router-link>
                </li>
            </ul>
            <div class="linkTo">
                <router-link to="/customproject" target="_blank">查看更多</router-link>
            </div>
        </div>
        <!-- 公司简介 -->
        <div class="about ">
            <div class="about_bg">
                <div class="titlels">
                    <span class="span-title">公司简介</span>
                </div>
                <p class="pro-sum">Company Profile</p>
                <div class="content content_flex">
                    <div class="content-box content_flex_box">
                        <div class="content_title"> &nbsp;&nbsp;关于我们&nbsp;&nbsp; </div>
                        <div class="content-text" v-html="about.content"></div>
                        <div class="linkTo">
                            <router-link to="/about">查看详情</router-link>
                        </div>
                    </div>
                    <div class="about_me">
                        <div class="qywh about_item" @mouseenter="qyhide=true" @mouseleave="qyhide=false">
                            <img :src="about.picture || qywh" alt="">
                            <p class="about_item_title" v-show="!qyhide">企业文化</p>
                            <div class="about_item_hide about_item_qywh" v-show="qyhide">
                                <div>
                                    <p v-for="item in about.sumary">{{item}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="lxwm about_item" @mouseenter="callhide=true" @mouseleave="callhide=false">
                            <img :src="call.picture || lxwm" alt="">
                            <p class="about_item_title" v-show="!callhide">联系我们</p>
                            <div class="about_item_hide" v-show="callhide">
                                <div>
                                    <p v-for="item in call.sumary">{{item}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
       <!-- 合作伙伴 -->
       <div class="hzhb">
            <div class="titlels">
                <span class="span-title">合作伙伴</span>
            </div>
            <p class="pro-sum">Cooperative Partner</p>
            <div class="content" style="width:1000px;margin:0 auto;">
               <img :src="about_hzhb.picture || connect" alt="" style="width:100%;">
               <div class="linkTo" style="margin-top:30px;">
                    <router-link to="/about/news/2">查看更多</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import xkzyjs from '@/assets/img/xkzyjs.jpg'
import fbsyzz from '@/assets/img/fbsyzz.jpg'
import kyfwywb from '@/assets/img/kyfwywb.jpg'
import qywh from '@/assets/img/qywh.png'
import lxwm from '@/assets/img/lxwm.png'
import connect from '@/assets/img/connect.png'
import context from '@/utils/context'
export default {
    name:'Index',
    data(){
        return{
            url:context.url,
            swiperOptions:{
                 pagination: {
                    el: '.swiper-pagination'
                },
                autoplay:true
            },
            swiperOptions2:{
                autoplay:true,
                slidesPerView: 3,
                spaceBetween: 20,
                // nextButton: '.swiper-button-next',
                // prevButton: '.swiper-button-prev',
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }
            },
            bannerList:[],
            about:{sumary:[]},
            project:[],
            call:{sumary:[]},
            newaddList:[
                {img:xkzyjs,title:'学科专业建设'},
                {img:fbsyzz,title:'非标实验装置'},
                {img:kyfwywb,title:'科研服务与外包'},
            ],
            about_hzhb:{},
            qyhide:false,
            callhide:false,
            qywh:qywh,
            lxwm:lxwm,
            connect:connect,
            p_nav_list:[],
            active_id:0,
            active_child:[]
        }
    },
    methods:{
        tohrefUrl(){
            this.$router.push({path:'/project'})
        },
        //bannner
        getIndexBanner(){
            axios.post('/api/index/banner').then(this.getBannerSuccess)
        },
        getBannerSuccess(res){
           this.bannerList = res.data;

        },
        //点击banner
        bannerTurn(){

        },
        //合作伙伴
        getAbout_hzhb(){
            axios.post('/api/index/about_hzhb').then((res)=>{
                this.about_hzhb = res.data[0] || {};   
            })
        },
        //关于我们
        getIndexAbout(){
            axios.post('/api/index/aboutme').then((res)=>{
                this.about = res.data[0] || {};   
                if(this.about.sumary){
                    const sumary = this.about.sumary.split(';')
                    sumary.forEach((a,i)=>{
                        sumary[i] = a.trim()
                    })
                    this.about.sumary = sumary
                }else{
                    this.about.sumary = []
                }
            })
        },
        //客户案例
        getIndexProject(){
            axios.post('/api/index/customproject',{
                isdisplay: 1,
                limit: 8,
                offset: 0
            }).then((res)=>{
                this.project = res.data;
            })
        },
        //联系我们
        getIndexCall(){
            axios.post('/api/index/contectme').then((res)=>{
                this.call = res.data[0] || {}
                if(this.call.sumary){
                    const sumary = this.call.sumary.split(';')
                    sumary.forEach((a,i)=>{
                        sumary[i] = a.trim()
                    })
                    this.call.sumary = sumary
                }else{
                    this.call.sumary = []
                }
            })
        },
        getPNavList(){
            axios.post('/api/index/cp_nv_list',{isdisplay:1}).then((res)=>{
                const data = res.data || []
                const dl = {}
                data.forEach(a=>{
                    if(!a.type){
                        dl[a.id] = {
                            ...a,
                            children:[]
                        }
                    }
                })
                data.forEach(a=>{
                    if(a.type){
                        const getP = dl[a.type]
                        if(getP){
                            getP.children.push(a)
                        }
                    }
                })
                for(let i in dl){
                    if(!dl[i].children.length){
                        const p = JSON.parse(JSON.stringify(dl[i]))
                        delete p.children
                        dl[i].children = [p]
                    }
                }
                let arr = Object.values(dl)
                arr.sort((a,b)=>{
                    return b.sort-a.sort
                })
                this.p_nav_list = arr
                if(this.p_nav_list.length){
                    this.active_id = this.p_nav_list[0].id
                    this.active_child = this.p_nav_list[0].children ||[]
                }
            })
        },
        changeitem(item){
            this.active_id = item.id
            this.active_child = item.children ||[]
        }
    },
    mounted(){
        this.getIndexBanner()
        this.getPNavList()
        this.getIndexAbout()
        this.getIndexProject()
        this.getIndexCall()
        this.getAbout_hzhb()
        // this.swiper.slideTo(3, 1000, false)
        // this.swiper2.slideTo(3, 1000, false)
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        },
        swiper2() {
            return this.$refs.mySwiper2.swiper
        }
    }
}
</script>

<style lang="scss">
.otrSweiper{
    width:1000px;
    margin:30px auto;
    .c_list{
        margin-top:30px;
        width:1000px;
        position: relative;
        .swiper-wrapper{
            height:220px;
        }
        .swiper-button-prev {
            left:-70px;
        }
        .swiper-button-next{
            right:-70px;
        }
        .one_p_title{
            position: absolute;
            z-index:100;
            font-weight: bold;
            width:100%;
            height:46px;
            bottom:0px;
            text-align:center;
            line-height:46px;
            font-size:18px;
            color:#ffffff;
            background-color: rgba(0, 0, 0,0.4);
        }
        .project_new_bg{
            background-size:cover !important;
            width:100%;
            height:100%;
        }
    }
    .p_list{
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction:row;
        flex-wrap: nowrap;
        justify-content:space-around;
        align-items:center;
        padding-bottom:20px;
        border-bottom:2px solid #05507D;
        .one_hull{
            width:110px;
            height:110px;
            border-radius:50%;
            border:1px solid #777;
            position: relative;
            cursor: pointer;
            img{
                position: absolute;
                top:50%;
                left:50%;
                transform:translateY(-50%) translateX(-50%);
                width:50px;
                height:50px;
            }
        }
        p{
            margin-top:10px;
            text-align:center;
        }
    }
}
    .swiper-wrapper{
        height: 400px;
    }
    .content{
        padding-bottom: 20px;
    }
    .titlels{
        text-align: center;
        font-size: 22px;
        line-height: 40px;
        color: #242c42;
		letter-spacing: 6px;
        font-weight: bold;
		.span-title{
			padding:0 6px 0 14px;
		    padding-bottom: 4px;
		    border-bottom: 2px solid #242c42;
		}
    }
    .newadd{
        display: flex;
        width:1000px;
        justify-content:space-between;
        margin:40px auto 0;
        .newadd_li{
            width:320px;
            height:180px;
            margin-right:20px;
            position:relative;
            border-radius:6px;
            overflow:hidden;
            img{
                width:100%;
                height:100%;
            }
            p{
                width:100%;height:100%;
                position:absolute;
                left:50%;
                right:50%;
                text-align:center;
                margin-top:-102px;
                margin-left:-160px;
                font-size:24px;
                line-height:24px;
                color:#242c42;
                font-weight:bold;
                letter-spacing:2px;
            }
            &:last-child{
                margin-right:0;
            }
            &:hover{
                cursor:pointer;
                p{
                    color:#b99663;
                }
            }
        }
    }
    .linkTo{
        text-align: center;
        width: 120px;
        height: 32px;
        background: #05507D;
        border-radius: 6px;
         margin: 0px auto;
        a{
            font-size: 15px;
            line-height: 32px;
            color: #fff;
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
    // 关于我们
    .about{
		margin-top:0px;
        .content{
            width: 1000px;
            margin: 10px auto 0;
			padding-bottom:40px;
            display: flex;
            img{
                width: 400px;
                height: 260px;
                margin-right: 30px;
            }
            .content-box{
                width: 1000px;
                .content_title{
                    font-size:18px;
                    font-weight: bold;
                    display: inline-block;
                    margin: 10px 0 0 10px;
                    line-height:30px;
                    border-bottom: 1px solid #777;
                }
                .content-text{
                    height: 322px;
                    padding:18px;
                    padding-top:8px;
                    overflow: hidden;
                    margin-bottom: 10px;
                    p{
                        font-size: 16px!important;
                        line-height: 30px!important;
                    }
                }
             
            }
        }
    }
    //产品推荐
    .project{
		padding-top:20px;
        background: #242c42;
        padding-bottom: 30px;
		.titlels{
		    
		    text-align: center;
		    font-size: 22px;
		    line-height: 40px;
		    color: #fff;
			
			letter-spacing: 6px;
		    font-weight: bold;
			.span-title{
				padding:0 6px 0 14px;
			    padding-bottom: 4px;
			    border-bottom: 2px solid #fff;
			}
		}
        
        ul{
            width: 1000px;
            margin: 20px auto 0;
            display: flex;
            flex-wrap:wrap;
            li{
                width: 235px;
                margin-bottom: 20px;
                margin-right: 18px;
				border-radius:6px;
				background:#efefef;
				box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
				
                img{
					padding:10px 0 0 12px;
                    width: 211px;
                    height: 190px;
                }
                .pro-title{
                    line-height:32px;
                    font-size:16px;
                    color:#242c42;
                    text-align:center;
                    overflow: hidden;    
                    text-overflow: ellipsis;  
                    white-space: nowrap;
					margin-left:5px;
                    width:230;
                }
            }:hover .pro-title{
				color:#b99663;
			}
            li:nth-of-type(4n){
                margin-right: 0;
            }
        }
    }
    .content_flex{
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction:row;
        flex-wrap: nowrap;
        justify-content:space-between;
        align-items:center;
        width:1000px;
        height:450px;
        .content_flex_box{
            width:660px !important;
            height:100%;
            background-color: #ffffff;
            border-radius: 6px;
        }
        .about_me{
            width:330px;
            height:100%;
        }
    }
    .pro-sum{
        margin: 10px auto 0;
        line-height: 40px;
        text-align: center;
        color: #777;
    } 
    .about_bg{
        padding-top:20px;
        width: 100%;
        background: no-repeat url(".././assets/img/about_bg.png");
        background-size:cover;
    }
    .hzhb{
        margin-top:20px;
    }
    .about_item{
        width:100%;
        height:220px;
        margin-bottom:10px;
        position: relative;
        img{
            width:100% !important;
            height:100% !important;
            margin-right:0 !important;
        }
        .about_item_title{
            position: absolute;
            z-index:1;
            bottom:30px;
            height:40px;
            text-align:center;
            background-color: rgba(192, 255, 62,0.7);
            color:#242c42;
            font-size:16px;
            line-height: 40px;
            width:100%;
            font-weight: bold;
        }
    }
    .about_item_hide{
        position: absolute;
        z-index:2;
        top:0;
        width:100%;
        height:100%;
        background-color: rgba(0,0,0,0.6);
        color:#ffffff;
        font-size:14px;
        text-align:left;
        transition: all 2s ease-out;
        div{
            position: absolute;
            width:100%;
            top:50%;
            transform:translateY(-50%);
        }
        p{
            line-height:30px;
            margin-left:10px;
            margin-right:10px;
        }
    }
    .about_item_qywh{
        font-size:13px;
        p{
            margin-left:4px;
            margin-right:0px;
        }
    }
</style>


