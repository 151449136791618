<template>
    <div id="project">
        <bg-img :name="name"></bg-img>
        <div class="box">
            <left-list :list="list" :name="name" @changeTitle="changeTitle" :navName="'customproject'"></left-list>
            <!--<router-view  :title="title" :id="id"></router-view>-->
            <router-view :key="key" :offer="off"></router-view>
        </div>
    </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    import axios from 'axios'
    import leftList from './leftList.vue'
    export default{
        name:'Project',
        components:{
            BgImg,
            leftList
        },
        data(){
            return{
                name:'客户案例',
                list:[],
                title:'',
                id:'',
                frist:0,
                key:'',
                off:false
            }
        },
        watch:{
            $route(to, from) {
                const id = to.params.id;
                if(!from.params.id){
                    let nid = this.frist;
                    this.$router.resolve({name:'CustomProject',params:{id:nid}})
                }
            }
        },
        methods:{
            //获取左侧列表
            getLeftList(){
                axios.post('/api/index/customlist').then((res)=>{
                    if(res.data.ret === false){
                        this.list = []
                        return
                    }
                   this.list = res.data;
                   this.frist = res.data[0].id
                })
            },
            changeTitle(title,id){
                this.key =  Math.random()
                this.title = title;
                this.id = id;

            }
        },
        computed: {
//            key() {
//                return Math.random()
//            }
        },
        mounted(){
            this.getLeftList();
        }
    }
</script>

<style lang="scss" scoped>
    #project{
       padding-bottom: 50px;
       .box{
           width: 1000px;
           margin: 30px auto;
           display: -webkit-box;
           display: -ms-flexbox;
           display: flex;
           -webkit-box-pack: justify;
           -ms-flex-pack: justify;
           justify-content: space-between;
        }
    }
</style>