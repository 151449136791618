<template>
    <div>
        <div :id="otrbg?'bgImg2':'bgImg'">
            <p>
                {{name}}
            </p>
        </div>
    </div>
    
</template>

<script>
    export default{
        props:{
            name:String,
            otrbg:{
                type:Number,
                default:0
            }
        }
    }
</script>

<style lang="scss" scoped>
    #bgImg{
        width:100%;
        height:120px;
        background: url(".././assets/img/bg1.png");
        min-width: 1000px;
        position:relative;
        p{
            font-size: 24px;
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            font-weight: 700;
            letter-spacing: 10px;
        }
    }
    #bgImg2{
        width:100%;
        height:120px;
        background: no-repeat url(".././assets/img/about_detail.png");
        background-size:cover;
        min-width: 1000px;
        position:relative;
        p{
            font-size: 24px;
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            font-weight: 700;
            letter-spacing: 10px;
        }
    }
</style>