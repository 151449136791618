/**
 * Created by yuhaisong on 2019/6/25.
 */
import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        title:''
    },
    mutations:{
        showTitle(state,title){
            // console.log(title)
            state.title = title;
            sessionStorage.setItem('title',state.title);   //sessionStorge保存
        }
    }

})

export default store