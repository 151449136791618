<template>
    <div id="project">
        <bg-img :name="name"></bg-img>
        <div class="box">
            <div id="leftList_new">
                <div class="title">{{name}}</div>
                <div class="line"></div>
                <ul>
                    <li v-for="item of list" :key="item.id" :class="item.type?'teshu':''" @click="handleClick(item.title,item.id)">
                        <router-link :to="{path:'/project/'+item.id}">
                            {{item.title}}
                        </router-link>
                    </li>
                </ul>
            </div>
            <!--<router-view  :title="title" :id="id"></router-view>-->
            <router-view :key="key" :offer="off"></router-view>
        </div>
    </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    import axios from 'axios'
    import leftList from './leftList.vue'
    export default{
        name:'Project',
        components:{
            BgImg,
            leftList
        },
        data(){
            return{
                name:'产品中心',
                list:[],
                title:'',
                id:'',
                frist:0,
                key:'',
                off:false
            }
        },
        watch:{
            $route(to, from) {
                const id = to.params.id;
                if(!from.params.id){
                    let nid = this.frist;
                    this.$router.resolve({name:'Project',params:{id:nid}})
                }
            }
        },
        methods:{
            //获取左侧列表
            handleClick(title){
                this.$store.commit('showTitle',title)
            },
            getLeftList(){
                axios.post('/api/index/cp_nv_list').then((res)=>{
                    const data = res.data || []
                    const dl = []
                    data.forEach(a=>{
                        if(!a.type){
                            dl.push(a)
                        }
                    })
                    const navList = {}
                    data.forEach(a=>{
                        if(a.type){
                            const ins = dl.findIndex(k=>k.id == a.type)
                            if(ins !=-1){
                                if(!navList[a.type]){
                                    navList[a.type] = []
                                }
                                navList[a.type].push(a)
                            }else{
                                dl.push(a)
                            }
                        }
                    })
                    let newNav = []
                    dl.forEach(a=>{
                        const getChild = navList[a.id] ||[]
                        newNav = newNav.concat(a)
                        newNav = newNav.concat(getChild)
                    })
                    // 对数据进行分类
                   this.list = newNav;
                   // 进行排序
                   this.frist = newNav[0].id
                })
            },
            changeTitle(title,id){
                this.key =  Math.random()
                this.title = title;
                this.id = id;

            }
        },
        computed: {
//            key() {
//                return Math.random()
//            }
        },
        mounted(){
            this.getLeftList();
        }
    }
</script>

<style lang="scss" scoped>
    #project{
       padding-bottom: 50px;
       .box{
           width: 1000px;
           margin: 30px auto;
           display: -webkit-box;
           display: -ms-flexbox;
           display: flex;
           -webkit-box-pack: justify;
           -ms-flex-pack: justify;
           justify-content: space-between;
        }
    }
    #leftList_new{
        width: 200px;
        padding: 20px;
        min-height: 200px;
        background: #f7f7f7;
		padding-top:12px;
        .title{
            line-height: 50px;
            font-size: 26px;
            text-align: left;
            color: #222845;
            letter-spacing: 10px;
            font-weight: 700;
			margin-top:0px !important;
        }
        .line{
            width: 80px;
            height: 2px;
            background: #222845;
        }
        ul{
            padding-top: 12px;
            li{
                width: 100%;
                a{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: bold;
                    width: 100%;
                    height: 100%;
                    line-height: 44px;
                }
                a.router-link-active{
                    color: #b99663!important;
                }
            }:hover a{
				color:#b99663!important;
			}
            .teshu{
                margin-left:20px;
                a{
                    font-weight: 400;
                    font-size:14px;
                    line-height:30px;
                }
            }
        }
    }
</style>