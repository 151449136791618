<template>
    <div id="content">
        <p class="con-title">联系我们</p>
        <div v-html="list.content">

        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default{
        data(){
            return{
                list:[]
            }
        },
        methods:{
            getContentMe(){
                axios.post('/api/index/contectme').then((res)=>{
                    this.list = res.data[0]
                })
            }
        },
        mounted(){
            this.getContentMe()
        }
    }
</script>

<style lang="scss" scoped>
    #content{
        width: 730px;
        margin-left: 30px;
        padding-top:10px;
        .con-title{
            font-size: 24px;
            color: #242c42;
            line-height: 36px;
        	font-weight: bold;
            letter-spacing: 4px;
            margin-bottom: 20px;
        	padding-bottom:14px;
        	border-bottom:2px solid #242c42;
        }
    }
</style>