<template>
    <div id="news">
        <!--背景图-->
        <bg-img :name="name"></bg-img>
        <div class="box">
            <!--左侧导航栏-->
            <div id="leftList">
                <div class="title">信息公示</div>
                <div class="line"></div>
                <ul>
                    <li>
                        <router-link to="/news/1">
                            新闻动态
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/news/4">
                            中标公示
                        </router-link>
                    </li>
                </ul>
            </div>
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    import axios from 'axios'
    export default{
        name:'News',
        components:{
            BgImg
        },
        data(){
            return{
                name:'新闻中心',
            }
        },
//        beforeRouteUpdate(to,from,next){
//            const id = to.params.id;
//            if(from.params.id != id){
//              next()
//              this.getList()
//            }
//
//        },
        methods:{
            //获取对应的新闻列表列表
            getList(){
                axios.post('/api/index/news',{
                    limit: 8,
                    offset: 0,
                    type:this.$route.params.id
                }).then(res =>{
                    console.log(res)
                })
            }
        }
//        created(){
//            const id = this.$route.params.id
//            console.log(id)
//        }
    }
</script>

<style lang="scss" scoped>
    #news{
        padding-bottom: 50px;
    }
    .box{
        width:1000px;
        margin:30px auto;
        display: flex;
        justify-content: space-between;
        #leftList{
            width: 200px;
            padding: 20px;
            min-height: 200px;
            background: #f7f7f7;
			padding-top:12px;
        .title{
            line-height: 50px;
            font-size: 24px;
            text-align: left;
            color: #222845;
            letter-spacing: 10px;
            font-weight: 700;
            margin-top:0px !important;
        }
        .line{
            width: 80px;
            height: 2px;
            background: #222845;
        }
        ul{
            padding-top: 12px;
            li{
                width: 100%;
                a{
                    display: inline-block;
                    font-size: 16px;
                    width: 100%;
                    height: 100%;
                    line-height: 44px;
                }
                a.router-link-active{
                    color: #b99663!important;
                }
            }:hover a{
				color:#b99663 !important;
			}
        }
        }
    }
</style>