<template>
    <div id="new">
        <p class="con-title">合作伙伴</p>
        <p v-if="list.length == 0" class="wuval">暂时没有相关内容</p>
        <ul v-if="list.length>0">
            <li v-for="item of list" :key="item.id">
                    <img :src="item.picture">
                    <p class="info">
                        {{item.title}}
                    </p>
            </li>
        </ul>
        <v-page :total-row="total" align="left" :page-size-menu="[12,16,24]" :info="false" @page-change="pagePhotoChange" v-if="list.length"></v-page>
    </div>
</template>

<script>
    import axios from 'axios'
    import context from '@/utils/context'
    export default{
        data(){
            return{
                url:context.url,
                total:0,
                list:[]
            }
        },
        methods:{
            getContentMe(limit,offset){
                axios.post('/api/index/news',{
                    limit: limit,
                    offset: offset,
                    type: 2     }).then((res)=>{
                    console.log(res)
                    this.total = Number(res.data.total);
                    this.list = res.data.rows
                })
            },
            pagePhotoChange(pInfo){
                let pageSize = pInfo.pageSize; //用户设置的请求条数
                let start = pInfo.pageSize * (pInfo.pageNumber-1); //从多少条开始请求
                let end = start + pageSize; //当前请求的最后一条

                if((end-pageSize)>=this.total){
                    console.log(111);
                    end=this.total
                }else{
                    console.log(end);
                    this.getContentMe(pageSize,start)
                }

            }
        },
        mounted(){
            this.getContentMe(8,0)
        }
    }
</script>

<style lang="scss" scoped>
    div.v-pagination>ul{
        li:first-child{
            display: none;
        }
    }
	.wuval{
		text-align:center;
		font-size:24px;
		letter-spacing: 4px;
		font-weight: bold;
		color:#ccc !important;
		margin-top:60px;
	}
    #new{
        width: 730px;
        margin-left: 30px;
        padding-top:10px;
        .con-title{
            font-size: 24px;
            color: #242c42;
            line-height: 36px;
        	font-weight: bold;
            letter-spacing: 4px;
            margin-bottom: 20px;
        	padding-bottom:14px;
        	border-bottom:2px solid #242c42;
        }
        ul{
            width:100%;
            margin-bottom:20px;
            display:flex;
            flex-wrap:wrap;
            li{
                width: 234px;
                margin-right: 14px;
                max-height: 250px;
                margin-bottom: 20px;
				border-radius: 6px;
				box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
                img{
					margin-top:10px;
					margin-left:12px;
                    width: 210px;
                    max-height: 190px;
                }
                .info{
                    line-height: 32px;
                    font-size: 16px;
                    color: #777;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 230px;
                    letter-spacing: 0;
					margin-left:6px;
                }
            }:hover .info{
					color:#b99663 !important;
				}
            li:nth-child(3n){
                margin-right: 0;
            }
        }
    }
</style>