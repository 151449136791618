<template>
	<div id="new">
		<p class="con-title">招聘中心</p>
		<p v-if="list.length == 0" class="wuval">暂时没有相关内容</p>
		<ul v-if="list.length>0">
			<li v-for="item of list" :key="item.id">
				<div class="li-box">
					<img src="@/assets/img/isdoc.png" alt="">
					<div class="info">
						<p class="down-title" v-html="item.title">

						</p>
						<p class="down-time" v-html="item.time">

						</p>
					</div>
					<p class="download">
						<!--<a :href="url+item.files">点击下载</a>-->
						<router-link :to="{path:'/detail/news/'+item.id}" target="_blank">点击查看</router-link>
					</p>
				</div>
			</li>
		</ul>
		<v-page :total-row="total" align="left" :page-size-menu="[8]" :info="false" @page-change="pagePhotoChange" v-if="list.length"></v-page>
	</div>
</template>

<script>
	import axios from 'axios'
    import context from '@/utils/context'
	export default {
		data() {
			return {
				url: context.url,
				total: 0,
				list: []
			}
		},
		methods: {
			getContentMe(limit, offset) {
				axios.post('/api/index/news', {
					limit: limit,
					offset: offset,
					type: 3
				}).then((res) => {
					console.log(res)
					this.total = Number(res.data.total);
					this.list = res.data.rows
				})
			},
			pagePhotoChange(pInfo) {
				let pageSize = pInfo.pageSize; //用户设置的请求条数
				let start = pInfo.pageSize * (pInfo.pageNumber - 1); //从多少条开始请求
				let end = start + pageSize; //当前请求的最后一条

				if ((end - pageSize) >= this.total) {
					console.log(111);
					end = this.total
				} else {
					console.log(end);
					this.getContentMe(pageSize, start)
				}

			}
		},
		mounted() {
			this.getContentMe(8, 0)
		}
	}
</script>

<style lang="scss" scoped>
	div.v-pagination>ul {
		li:first-child {
			display: none;
		}
	}

	.wuval {
		text-align: center;
		font-size: 24px;
		letter-spacing: 4px;
		font-weight: bold;
		color: #ccc !important;
		margin-top: 60px;
	}

	#new {
		width: 730px;
		margin-left: 30px;
		padding-top: 10px;

		.con-title {
			font-size: 24px;
			color: #242c42;
			line-height: 36px;
			font-weight: bold;
			letter-spacing: 4px;
			margin-bottom: 20px;
			padding-bottom: 14px;
			border-bottom: 2px solid #242c42;
		}

		ul {
			width: 100%;
			margin-bottom: 20px;

			li {
				width: 100%;
				padding: 10px 0;
				border-bottom: 1px dashed #aaa;
				//margin-bottom:20px;
				height: 50px;

				.li-box {
					height: 100%;
					display: flex;

					p {
						letter-spacing: 0;
					}

					img {
						width: 50px;
						height: 50px;
						margin-right: 10px;

					}

					.info {
						width: 500px;

						.down-title {
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 10px;
							color: #333;
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.down-time {
							font-size: 14px;
							line-height: 20px;
							color: #777;
						}
					}

					.download {
						width: 100px;
						height: 30px;
						margin-top: 0;

						a {
							display: inline-block;
							width: 100%;
							height: 100%;
							background: #00a5e1;
							border-radius: 6px;
							line-height: 30px;
							text-align: center;
							color: #fff;
							font-size: 14px;
						}
					}
				}

			}

			li:first-child {
				padding-top: 0;
			}

			li:last-child {
				border-bottom: none;
			}
		}
	}
</style>
