<template>
  <div id="app">
    <Head></Head>
    <router-view/>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from './components/head.vue'
import Foot from './components/foot.vue'

export default {
  name: 'app',
  components: {
    Head,
    Foot
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height:100%;
}
html,body{
    height:100%;
}
a{
  text-decoration: none;
  color: #333;
}
ul,p{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
</style>
