<template>
   <div id="about">
        <!--背景图-->
        <bg-img :name="name" :otrbg="1"></bg-img>
        <div class="box">
            <!--左侧导航栏-->
            <div id="leftList">
                <div class="title">关于我们</div>
                <div class="line"></div>
                <ul>
                    <li>
                        <router-link to="/about/aboutMe">
                            公司简介
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about/ry">
                            荣誉资质
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about/news/2">
                            合作伙伴
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about/content">
                            联系我们
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about/news/3">
                            招聘信息
                        </router-link>
                    </li>
                </ul>
            </div>

            <router-view></router-view>
        </div>

   </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    export default{
        name:'AboutBox',
        components:{
            BgImg
        },
        data(){
            return{
                name:'关于我们',

            }
        }
    }
</script>

<style lang="scss" scoped>
    #about{
        padding-bottom: 50px;
    }
    .box{
        width:1000px;
        margin:30px auto;
        display: flex;
        justify-content: space-between;
        #leftList{
            width: 200px;
            padding: 20px;
            min-height: 200px;
            background: #f7f7f7;
			padding-top:12px;
            .title{
                line-height: 50px;
                font-size: 24px;
                text-align: left;
                color: #222845;
                letter-spacing: 10px;
                font-weight: 700;
                margin-top:0px !important;
            }
            .line{
                width: 80px;
                height: 2px;
                background: #222845;
            }
            ul{
                padding-top: 12px;
                li{
                    width: 100%;
                    a{
                        display: inline-block;
                        font-size: 16px;
                        width: 100%;
                        height: 100%;
                        line-height: 44px;
                    }
                    a.router-link-active{
                        color: #b99663!important;
                    }
                }:hover a{
					color:#b99663 !important;
				}
            }
        }
    }
</style>