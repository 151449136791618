<template>
    <div id="foot_box">
        <div id="foot">
            <div class="foot_flex">
                <div class="foot_left">
                    <div class="foot_nav_list">
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/about/aboutMe">关于我们</router-link>
                            </div>
                            <p class="li_list">
                                <router-link to="/about/aboutMe">公司简介</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/about/ry">荣誉资质</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/about/news/2">合作伙伴</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/about/content">联系方式</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/about/news/3">招聘信息</router-link>
                            </p>
                        </div>
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/about/news/2">产品中心</router-link>
                            </div>
                            <p class="li_list" v-for="item in cp_list" @click="handleClick(item.title,item.id)">
                                <router-link :to="'/project/'+item.id" >{{item.title2||item.title}}</router-link>
                            </p>
                        </div>
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/customproject">客户案例</router-link>
                            </div>
                        </div>
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/cxyzx/about">产学研中心</router-link>
                            </div>
                            <p class="li_list">
                                <router-link to="/cxyzx/about">中心概况</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/cxyzx/work/0">服务项目</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/cxyzx/service/0">产学合作</router-link>
                            </p>
                        </div>
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/news/1">信息公示</router-link>
                            </div>
                            <p class="li_list">
                                <router-link to="/news/1">新闻动态</router-link>
                            </p>
                            <p class="li_list">
                                <router-link to="/news/4">中标公示</router-link>
                            </p>
                        </div>
                        <div class="one_li">
                            <div class="li_title">
                                <router-link to="/about/content">联系我们</router-link>
                            </div>
                        </div>
                    </div>
                    <p class="beian">
                        <a href="http://www.beian.miit.gov.cn" target="_blank">
                            京ICP备19023194号-1版权所有：北京叁参研学科技有限公司
                        </a>
                    </p>
                </div>
                <div class="foot_right">
                    <div></div>
                    <p>办公地址：</p>
                    <div>
                        <p>总公司地址：北京市海淀区清河龙岗路51号M51科创中心D座2009</p>
                        <p>华东分公司：江苏省苏州市吴江商会大厦北楼712室</p>
                        <p>东北分公司：辽宁省沈阳市沈河区青年大街173号佳兆业中心B座2309室</p>
                    </div>
                    <p>联系电话：</p>
                    <div>
                        <p>张经理 152 1087 9404 （华北、华中、华南地区）</p>
                        <p>韩经理 151 1423 1211 （东北地区）</p>
                        <p>潘经理 182 5110 6635 （华东地区）</p>
                        <p>马经理 134 6951 1214 （西北、华东、西南地区）</p>
                    </div>
                    <p>电子邮箱：sancanstudies@163.com</p>
                    <p>公众号:</p>
                    <div class="gzh"></div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'Index',
    data(){
        return {
            cp_list:[]
        }
    },
    mounted() {
        this.getcp_nv_list()
    },
    methods: {
        // cp_nv_list
        getcp_nv_list(){
            axios.post('/api/index/cp_nv_list',{
                dl:1
            }).then((res)=>{
                this.cp_list = res.data || []; 
            })
        },
        handleClick(title){
            this.$store.commit('showTitle',title)
        },
    },
}
</script>

<style lang="scss" scoped>
#foot_box{
    width:100%;
    background: #242c42;
    height:370px;
}
.foot_flex{
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content:space-between;
    .foot_left{
        width:690px;
    }
    .foot_right{
        width:300px;
        color:#F8F8FF;
        font-size:13px;
        line-height:20px;
        margin-top:20px;
        padding-left:10px;
        border-left:1px solid #B3B3B3;
    }
    .gzh{
        background: url(".././assets/img/wx.png");
        background-size:cover;
        height:80px;
        width:80px;
        margin:0 auto;
    }
}
    #foot{
        width:1000px;
        margin:0 auto;
        .beian{
            width:380px;
            height: 50px;
            position: absolute;
            // border-top:1px solid #B3B3B3;
            bottom:0;
            text-align:left;
            a{
                text-align: center;
                line-height: 50px;
                font-size: 14px;
                color: #F8F8FF;
            }
        }
    }
    .foot_nav_list{
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction:row;
        flex-wrap: nowrap;
        justify-content:space-between;
        margin-top:20px;
        .one_li{
            width:98px;
            .li_title{
                margin-bottom:10px;
                a{
                    font-size:18px;
                    font-weight: bold;
                    line-height:30px;
                }
            }
            .li_list{
                a{
                    font-size:14px;
                    line-height:30px;
                }
            }
            a{
                color:#F8F8FF;
            }
        }
    }
</style>