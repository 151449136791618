<template>
    <div id="news">
        <!--背景图-->
        <bg-img :name="name"></bg-img>
        <div class="box">
            <!--左侧导航栏-->
            <div id="leftList">
                <div class="title">产学研中心</div>
                <div class="line"></div>
                <ul class="ful">
                    <li class="fli">
                        <router-link to="/cxyzx/about">
                            中心概况
                        </router-link>
                    </li>
                    <li class="fli">
                        <p>服务项目</p>
                    </li>
                    <li class="cli" v-for="(item,index) in worklist" :key="item.id+'1'">
                        <router-link :to="'/cxyzx/work/'+item.id">
                            {{index+1}}、{{item.title}}
                        </router-link>
                    </li>
                    <li class="fli">
                        <p>产学合作</p>
                    </li>
                    <li class="cli" v-for="(item,index) in servicelist" :key="item.id+'2'">
                        <router-link :to="'/cxyzx/service/'+item.id">
                            {{index+1}}、{{item.title}}
                        </router-link>
                    </li>
                    <li class="fli">
                        <router-link to="/cxyzx/concate">
                            联系我们
                        </router-link>
                    </li>
                </ul>
            </div>
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import BgImg from './bgImg.vue'
    import axios from 'axios'
    import context from '../utils/context'
    export default{
        name:'Cxyzx',
        components:{
            BgImg
        },
        data(){
            return{
                name:'产学研中心',
                servicelist:[],
                worklist:[]
            }
        },
//        beforeRouteUpdate(to,from,next){
//            const id = to.params.id;
//            if(from.params.id != id){
//              next()
//              this.getList()
//            }
//
//        },
        created(){
            this.getList()
        },
        methods:{
            //获取对应的新闻列表列表
            getList(){
                context.servicelist = {}
                context.worklist = {}
                axios.post('/api/index/servicelist').then(res =>{
                    if(res.status == 200){
                        this.servicelist = res.data || []
                        // this.servicelist.forEach(item=>{
                        //     context.servicelist[item.id] = item
                        // })
                    }
                })
                axios.post('/api/index/worklist').then(res =>{
                    if(res.status == 200){
                        this.worklist = res.data || []
                        // this.worklist.forEach(item=>{
                        //     context.worklist[item.id] = item
                        // })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #news{
        padding-bottom: 50px;
    }
    .box{
        width:1000px;
        margin:30px auto;
        display: flex;
        justify-content: space-between;
        #leftList{
            width: 200px;
            padding: 20px;
            min-height: 200px;
            background: #f7f7f7;
			padding-top:12px;
        .title{
            line-height: 50px;
            font-size: 24px;
            text-align: left;
            color: #222845;
            letter-spacing: 10px;
            font-weight: 700;
            margin-top:0px !important;
        }
        .line{
            width: 80px;
            height: 2px;
            background: #222845;
        }
        .ful{
            padding-top: 12px;
            .fli{
                width: 100%;
                a{
                    display: inline-block;
                    font-size: 16px;
                    width: 100%;
                    height: 100%;
                    line-height: 44px;
                }
                a.router-link-active{
                    color: #b99663!important;
                }
            }:hover a{
				color:#b99663 !important;
			}
            .cli{
                width: 100%;
                padding-left:14px;
                a{
                    display: inline-block;
                    font-size: 15px;
                    width: 100%;
                    height: 100%;
                    line-height: 44px;
                }
                a.router-link-active{
                    color: #b99663!important;
                }
            }:hover a{
				color:#b99663 !important;
			}
        }
        
        }
    }
</style>