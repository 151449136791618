<template>
    <div id="new">
        <p class="con-title">中标公示</p>
        <p v-if="list.length == 0" class="wuval">暂时没有相关内容</p>
        <ul v-if="list.length>0">
            <li v-for="item of list" :key="item.id">
                <router-link :to="{path:'/detail/news/'+item.id}" target="_blank">
                <div class="one-img" v-if="item.picture">
                    <img :src="item.picture">
                    <div class="info">
                        <div class="title-news">
                            <p>
                                {{item.title}}
                            </p>
                            <span>
                                {{item.time}}
                            </span>
                        </div>
                        <div class="sumary-news">
                            {{item.sumary}}
                        </div>
                    </div>
                </div>
                <div class="no-img" v-if="!item.picture">
                    <div class="title-news">
                        <p>
                            {{item.title}}
                        </p>
                        <span>
                                {{item.time}}
                            </span>
                    </div>
                    <div class="sumary-news">
                        {{item.sumary}}
                    </div>
                </div>
                </router-link>
            </li>
        </ul>
        <v-page :total-row="total" align="left" :page-size-menu="[8]" :info="false" @page-change="pagePhotoChange" v-if="list.length"></v-page>
    </div>
</template>

<script>
    import axios from 'axios'
    export default{
        data(){
            return{
                total:0,
                list:[]
            }
        },
        methods:{
            getContentMe(limit,offset){
                axios.post('/api/index/news',{
                    limit: limit,
                    offset: offset,
                    type: 4     }).then((res)=>{
                    this.total = Number(res.data.total);
                    this.list = res.data.rows
                })
            },
            pagePhotoChange(pInfo){
                let pageSize = pInfo.pageSize; //用户设置的请求条数
                let start = pInfo.pageSize * (pInfo.pageNumber-1); //从多少条开始请求
                let end = start + pageSize; //当前请求的最后一条
                if((end-pageSize)>=this.total){
                    end=this.total
                }else{
                    this.getContentMe(pageSize,start)
                }

            }
        },
        mounted(){
            this.getContentMe(8,0)
        }
    }
</script>

<style lang="scss" scoped>
    div.v-pagination>ul{
        li:first-child{
            display: none;
        }
    }
	.wuval{
		text-align:center;
		font-size:24px;
		letter-spacing: 4px;
		font-weight: bold;
		color:#ccc !important;
		margin-top:60px;
	}
    #new{
        width: 730px;
        margin-left: 30px;
        padding-top:10px;
        .con-title{
            font-size: 24px;
            color: #242c42;
            line-height: 36px;
        	font-weight: bold;
            letter-spacing: 4px;
            margin-bottom: 20px;
        	padding-bottom:14px;
        	border-bottom:2px solid #242c42;
        }
        ul{
            width:100%;
            margin-bottom:20px;
            li{
                width:100%;
                padding:10px 0;
                border-bottom: 1px dashed #aaa;
                //margin-bottom:20px;
                .one-img{
                    display: flex;
                    height: 90px;
                    img{
                        width: 160px;
                        height: 90px;
                        margin-right: 20px;
                    }
                    .info{
                        width:550px;
                        .title-news{
                            margin-bottom: 10px;
                            display: flex;
                            justify-content: space-between;
                            p{
                                width: 430px;
                                font-size: 16px;
                                line-height: 20px;
                                float: left;
                                color: #333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                letter-spacing: 0px;
                                margin-bottom: 0;
                            }
                            span{
                                font-size: 14px;
                                color: #777;
                                line-height: 20px;
                            }
                        }
                        .sumary-news{
                            width: 100%;
                            height: 56px;
                            line-height: 28px;
                            font-size: 14px;
                            color: #777;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
                .no-img{
                    display: flex;
                    flex-direction: column;
                    .title-news{
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        p{
                            font-size: 16px;
                            line-height: 20px;
                            color: #333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            letter-spacing: 0px;
                            margin-bottom: 0;
                        }
                        span{
                            font-size: 14px;
                            color: #777;
                            line-height: 20px;
                        }
                    }
                }
            }:hover p{
					color:#b99663 !important;
				}
            li:first-child{
                padding-top: 0;
            }
            li:last-child{
                border-bottom: none;
            }
        }
    }
</style>