<template>
    <div id="new">
        <p class="con-title" :key="key">{{title}}</p>
		<p v-if="list.length == 0" class="wuval">暂时没有相关产品</p>
        <ul v-if="list.length>0">
            <li v-for="item of list" :key="item.id">
                <router-link :to="{path:'/detail/project/'+item.id}"  ref="child">
                    <img :src="item.picture">
                    <p class="info">
                        {{item.title}}
                    </p>
                </router-link>
            </li>
        </ul>
        <v-page v-if="list.length>0 && showpagenub" :total-row="total" align="left"  :page-size-menu="[6]" :info="false" @page-change="pagePhotoChange"></v-page>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapState,mapMutations} from 'vuex'
    export default{
        props:{
//            title:String,
//            id:Number
            offer:Boolean
        },
        data(){
            return{
                total:0,
                list:[],
                type:'',
                urlId:0,
                key:'',
				showpagenub:true
            }
        },
//        beforeRouteLeave(to,from,next){
//            const id = to.params.id;
//            if(from.params.id != id){
//              next(function () {
//                  this.title()
//              })
//
//            }
//        },
        beforeRouteUpdate(to,from,next){
			this.showpagenub = false;
            const id = to.params.id;
            if(from.params.id != id){
              next()
              this.type = to.params.id
              this.getList(6,0,id)
            }

        },
//        watch:{
//            $route(to, from) {
//                const id = from.params.id;
//
//                if(to.params.id != id){
//                   this.$nextTick(function () {
//                        this.title = this.title;
//                    })
//                    console.log(this.$store.state.title)
//                   this.title = this.$store.state.title
//                    this.type = id
//                  this.getList(2,0,id)
//                }
//            }
//        },
        methods:{
            getList(limit,offset,type){
                axios.post('/api/index/project',{
                    limit: limit,
                    offset: offset,
                    type: type     }).then((res)=>{
                    this.total = Number(res.data.total);
                    this.list = res.data.rows;
					if(res.data.rows.length>0){
						this.urlId = res.data.rows[0].id
					}
                    this.showpagenub = true;
                })
            },
            pagePhotoChange(pInfo){
                let pageSize = pInfo.pageSize; //用户设置的请求条数
                let start = pInfo.pageSize * (pInfo.pageNumber-1); //从多少条开始请求
                let end = start + pageSize; //当前请求的最后一条

                if((end-pageSize)>=this.total){
                    end=this.total
                }else{
                    this.getList(pageSize,start,this.type)
                }
            }
        },
        computed:{
            title(){
                this.key = Math.random()
                if(this.$store.state.title){
                    return this.$store.state.title
                }else{
                    return sessionStorage.getItem('title');
                }

            }
       },
       mounted(){
            let id = this.$route.params.id;
			this.type = id;
           this.getList(6,0,id)
       }
    }
</script>

<style lang="scss" scoped>
    div.v-pagination>ul{
    li:first-child{
        display: none;
    }
    }
	.wuval{
		text-align:center;
		font-size:24px;
		letter-spacing: 4px;
		font-weight: bold;
		color:#ccc;
		margin-top:60px;
	}
    #new{
        width: 730px;
        margin-left: 30px;
        padding-top:10px;
        .con-title{
            font-size: 24px;
            color: #242c42;
            line-height: 36px;
			font-weight: bold;
            letter-spacing: 4px;
            margin-bottom: 20px;
			padding-bottom:14px;
			border-bottom:2px solid #242c42;
        }
        ul{
            width:100%;
            margin-bottom:20px;
            display:flex;
            flex-wrap:wrap;
            li{
                width: 234px;
                margin-right: 14px;
                height: 250px;
                margin-bottom: 20px;
				border-radius: 6px;
				overflow: hidden;
				box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
                img{
					margin-top:10px;
					margin-left:12px;
                    width: 210px;
                    height: 190px;
                }
                .info{
                    line-height: 32px;
                    font-size: 16px;
                    color: #777;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 230px;
                    letter-spacing: 0;
					margin-left:6px;
                }
            }:hover .info{
					color:#b99663 !important;
				}
            li:nth-child(3n){
                margin-right: 0;
            }
        }
    }
</style>