<template>
    <div id="leftList">
        <div class="title">{{name}}</div>
        <div class="line"></div>
        <ul>
            <li v-for="item of list" @click="handleClick(item.title,item.id)" :key="item.id">
                <router-link :to="{path:'/'+navName+'/'+item.id}">
                    {{item.title}}
                </router-link>
            </li>
        </ul>

    </div>
</template>

<script>

    export default{
        props:{
            list:Array,
            name:String,
            navName:{
                type:String,
                default:'project'
            }
        },
        methods:{
            handleClick(title){
                this.$store.commit('showTitle',title)
            }
//            handleClick(title,id){
//                console.log(title);
//                this.$emit('changeTitle',title,id)
//            }
        }
    }
</script>

<style lang="scss" scoped>
    #leftList{
        width: 200px;
        padding: 20px;
        min-height: 200px;
        background: #f7f7f7;
		padding-top:12px;
        .title{
            line-height: 50px;
            font-size: 26px;
            text-align: left;
            color: #222845;
            letter-spacing: 10px;
            font-weight: 700;
			margin-top:0px !important;
        }
        .line{
            width: 80px;
            height: 2px;
            background: #222845;
        }
        ul{
            padding-top: 12px;
            li{
                width: 100%;
                a{
                    display: inline-block;
                    font-size: 15px;
                    width: 100%;
                    height: 100%;
                    line-height: 44px;
                }
                a.router-link-active{
                    color: #b99663!important;
                }
            }:hover a{
				color:#b99663!important;
			}
        }
    }
</style>